.parallax {
  animation: parallax linear;
  animation-timeline: scroll(root);
}

@keyframes parallax {
  to {
    transform: translateY(calc(var(--parallax-speed) * 200px));
  }
}
